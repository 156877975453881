'use client';

import styles from './layout.module.scss';
import SideNav from '@/components/nav/sideNav';
import TopNav from '@/components/nav/topNav';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Toaster } from 'react-hot-toast';
import { useContext, useEffect, useState } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { redirect, useParams, usePathname, useRouter } from 'next/navigation';
import { SpinnerWrapped } from '@/components/spinner/spinner';
import { DiscordUserContext, ShapeContext } from '@/app/providers';
import useSWR, { mutate } from 'swr';

function Layout({ children }) {
    const { user, error: userError, isLoading } = useUser();
    const [menuOpen, setMenuOpen] = useState(false);
    const router = useRouter();
    const params = useParams();
    const pathname = usePathname();
    const { setDiscordUser } = useContext(DiscordUserContext);
    const { setShapes } = useContext(ShapeContext);

    const { isLoading: discordUserLoading } = useSWR(
        user ? '/api/users/discord' : null,
        (url) =>
            fetch(url).then(async (r) => {
                const json = await r.json();

                if (r.ok) {
                    setDiscordUser(json);
                } else {
                    window.location.replace('/api/auth/forceLogout');
                }

                return json;
            }),
        { revalidateOnFocus: false }
    );

    useEffect(() => {
        if (user) {
            Crisp.user.setEmail(`${user.name}@shapes.inc`);
            Crisp.user.setAvatar(user.picture);
            Crisp.user.setNickname(user.name);

            // Google Analytics
            window.gtag('set', { user_id: user.id });
            window.gtag('set', { user_data: { ...user } });

            fetch('/api/shapes').then(async (r) => {
                const shapes = await r.json();

                const shapeMap = Object.fromEntries(
                    shapes.map((e) => [e.username, e])
                );
                setShapes((prevShapes) => ({ ...prevShapes, ...shapeMap }));

                // For each shape set the SWR cache, so we can individually prune it
                shapes.forEach((s) => {
                    mutate(`/shapes/${s.id}`, s, { populateCache: false });
                });

                // check if a username is in the url
                // if it is, set shape to that and forward

                if (!params.username && pathname === '/') {
                    router.replace('/explore');
                }
            });
        }
    }, [user]);

    if (isLoading || discordUserLoading) {
        return <SpinnerWrapped />;
    }

    if (!isLoading && !user) {
        if (pathname !== '/') {
            redirect(`/home?returnTo=${encodeURIComponent(pathname)}`);
        } else {
            redirect(`/home`);
        }

        return null;
    }

    return (
        <div className={styles.wrapper}>
            <Toaster />
            <TopNav setMenuOpen={setMenuOpen} />
            <div className={styles.dashboard}>
                <SideNav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                <main className={styles.main}>{children}</main>
            </div>
        </div>
    );
}

export default Layout;
