'use client';
import styles from './sideNav.module.scss';
import ActiveLink from '@/components/link/activeLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFaceSmileHorns,
    faGraduationCap,
    faSquarePlus,
    faUser,
    faChartSimple,
    faBook,
    faDumbbell,
    faBrainCircuit,
    faMicrochipAi,
    faCog,
    faCoinBlank,
    faServer,
    faImageLandscape,
    faInfoSquare, faDebug, faBug,
} from '@fortawesome/pro-solid-svg-icons';

import {faDiscord, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { useContext, useMemo } from 'react';
import { ShapeContext, ShapeUserContext } from '@/app/providers';
import { clsx } from 'clsx';
import SelectedShape from '@/components/selectedShape/selectedShape';
import { adminSubs, isUserShapeCreator } from '@/admins';
import { useUser } from '@auth0/nextjs-auth0/client';
import { usePathname } from 'next/navigation';

function SideNav({ menuOpen, setMenuOpen }) {
    const { user } = useUser();
    const { shapeUser } = useContext(ShapeUserContext);
    const { shape } = useContext(ShapeContext);
    const pathname = usePathname();

    const onAddToServer = () => {
        window.open(
            `https://discord.com/oauth2/authorize?client_id=${shape?.app_info?.bot_id}&permissions=67584&scope=bot`,
            'popup',
            'width=500,height=766'
        );
        return false;
    };

    const app_id = useMemo(() => {
        return shape?.app_info?.bot_id;
    }, [shape, JSON.stringify(shape)]);

    const showDiscordSetup = useMemo(
        () =>
            (shape && (!shape.discord_bot_token_exist)) ||
            pathname.startsWith('/create'),
        [shape, pathname]
    );
    // const showXSetup = useMemo(
    //     () =>
    //         (shape && (!shape.x_id)) ||
    //         pathname.startsWith('/create'),
    //     [shape, pathname]
    // );
    // const showXSetup = useMemo(() => false, []);
    const showXSetup = useMemo(
        () =>
            adminSubs.includes(user?.sub)
                ? (shape && (!shape.x_id)) || pathname.startsWith('/create')
                : false,
        [shape, pathname, user]
    );
    
    // const showSetup = useMemo(
    //     () =>
    //         (shape && (!shape.discord_bot_token_exist || !shape.x_id)
    //             && isUserShapeCreator(user, shapeUser, shape)) ||
    //         pathname.startsWith('/create'),
    //     [shape, pathname]
    // );
    // const showSetup = useMemo(
    //     () =>
    //         (shape && (!shape.discord_bot_token_exist)
    //             && isUserShapeCreator(user, shapeUser, shape)) ||
    //         pathname.startsWith('/create'),
    //     [shape, pathname]
    // );
    const showSetup = useMemo(
        () =>
            adminSubs.includes(user?.sub)
                ? (shape && (!shape.discord_bot_token_exist || !shape.x_id)
                      && isUserShapeCreator(user, shapeUser, shape)) ||
                  pathname.startsWith('/create')
                : (shape && (!shape.discord_bot_token_exist)
                      && isUserShapeCreator(user, shapeUser, shape)) ||
                  pathname.startsWith('/create'),
        [shape, pathname, user, shapeUser]
    );

    return (
        <nav className={clsx([styles.nav, menuOpen ? styles.navOpen : ''])}>
            <SelectedShape setMenuOpen={setMenuOpen}/>
            {showSetup && (
                <>
                    {!shape && (
                        <ul>
                            <li onClick={() => setMenuOpen(false)}>
                                <ActiveLink
                                    className={styles.navLink}
                                    href={`/create`}
                                >
                                    <FontAwesomeIcon icon={faFaceSmileHorns}/>
                                    Personality
                                </ActiveLink>
                            </li>
                        </ul>
                    )}
                </>
            )}

            {shape && (
                <>
                    <label>
                        Integrations
                    </label>
                    <ul>
                        {showSetup && showDiscordSetup && (
                            <li
                                onClick={() => setMenuOpen(false)}
                                className={!shape ? styles.disabled : ''}
                            >
                                <ActiveLink
                                    className={styles.navLink}
                                    href={
                                        !shape ? null : `/${shape.username}/token`
                                    }
                                >
                                    <FontAwesomeIcon icon={faDiscord}/>
                                    Setup Discord Bot
                                </ActiveLink>
                            </li>
                        )}

                        {showSetup && showXSetup && (
                            <li
                                onClick={() => setMenuOpen(false)}
                                className={!shape ? styles.disabled : ''}
                            >
                                <ActiveLink
                                    className={styles.navLink}
                                    href={
                                        !shape ? null : `/${shape.username}/x-connect`
                                    }
                                >
                                    <FontAwesomeIcon icon={faXTwitter}/>
                                    Setup X Account
                                </ActiveLink>
                            </li>
                        )}

                        {shape.x_id && (
                            <li onClick={() => setMenuOpen(false)}>
                                <a
                                    className={styles.navLink}
                                    href={`https://x.com/i/user/${shape.x_id}`}
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faXTwitter}/>
                                    {shape.name}'s X Profile
                                </a>
                            </li>
                        )}

                        {shape.discord_bot_token_exist && app_id && (
                            <li onClick={() => setMenuOpen(false)}>
                                <a
                                    className={styles.navLink}
                                    href={`https://discord.com/oauth2/authorize?client_id=${app_id}&permissions=67584&scope=bot`}
                                    target="popup"
                                    onClick={onAddToServer}
                                >
                                    <FontAwesomeIcon icon={faDiscord}/>
                                    Add to Discord Server
                                </a>
                            </li>
                        )}
                    </ul>
                </>
            )}

            {shape && isUserShapeCreator(user, shapeUser, shape) && shape.x_id && (
                <>
                    <label> X Configuration </label>
                    <br></br>
                    <label> Shape </label>
                    <ul>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/x-free-will`}
                            >
                                <FontAwesomeIcon icon={faMicrochipAi}/>
                                Free Will
                            </ActiveLink>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/x-personality`}
                            >
                                <FontAwesomeIcon
                                    icon={faFaceSmileHorns}
                                />
                                Personality
                            </ActiveLink>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/x-engine`}
                            >
                                <FontAwesomeIcon icon={faMicrochipAi}/>
                                AI Engine
                            </ActiveLink>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/x-debug`}
                            >
                                <FontAwesomeIcon icon={faBug}/>
                                Debug
                            </ActiveLink>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/x-general`}
                            >
                                <FontAwesomeIcon icon={faCog}/>
                                Settings
                            </ActiveLink>
                        </li>
                    </ul>

                    <label> Help </label>
                    <ul>
                        <li onClick={() => setMenuOpen(false)}>
                            <a
                                className={styles.navLink}
                                href="https://x.com/messages/compose?recipient_id=1288708818361360384&text=hi%20i%20have%20a%20question"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faXTwitter}/>
                                Support
                            </a>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <a
                                className={styles.navLink}
                                href="https://x-beta.shapes.inc/guide-c0791317454b4211b448ebe7c611d7f2"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faInfoSquare}/>
                                User Manual
                            </a>
                        </li>
                    </ul>

                </>
            )}

            {shape && shape.discord_bot_token_exist && (
                <>

                    <label> Discord Configuration </label>
                    <br></br>

                    {!showDiscordSetup && (
                        <>
                            <label> Analytics </label>
                            <ul>
                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/analytics`}
                                    >
                                        <FontAwesomeIcon icon={faChartSimple}/>
                                        Shape Stats
                                    </ActiveLink>
                                </li>
                            </ul>
                        </>
                    )}

                    {isUserShapeCreator(user, shapeUser, shape) && (
                        <>
                            <label>Shape</label>
                            <ul>
                                {!showDiscordSetup && (
                                    <li onClick={() => setMenuOpen(false)}>
                                        <ActiveLink
                                            className={styles.navLink}
                                            href={`/${shape.username}/profile`}
                                        >
                                            <FontAwesomeIcon icon={faUser}/>
                                            Profile
                                        </ActiveLink>
                                    </li>
                                )}

                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/personality`}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFaceSmileHorns}
                                        />
                                        Personality
                                    </ActiveLink>
                                </li>

                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/free-will`}
                                    >
                                        <FontAwesomeIcon icon={faMicrochipAi}/>
                                        Free Will
                                    </ActiveLink>
                                </li>

                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/knowledge`}
                                    >
                                        <FontAwesomeIcon icon={faBook}/>
                                        Knowledge
                                    </ActiveLink>
                                </li>

                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/training`}
                                    >
                                        <FontAwesomeIcon icon={faDumbbell}/>
                                        Training
                                    </ActiveLink>
                                </li>

                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/engine`}
                                    >
                                        <FontAwesomeIcon icon={faMicrochipAi}/>
                                        AI Engine
                                    </ActiveLink>
                                </li>

                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/image`}
                                    >
                                        <FontAwesomeIcon icon={faImageLandscape}/>
                                        Image Engine
                                    </ActiveLink>
                                </li>


                                <li onClick={() => setMenuOpen(false)}>
                                    <ActiveLink
                                        className={styles.navLink}
                                        href={`/${shape.username}/general`}
                                    >
                                        <FontAwesomeIcon icon={faCog}/>
                                        Settings
                                    </ActiveLink>
                                </li>
                            </ul>
                        </>
                    )}

                    <label>Server</label>
                    <ul>
                        {app_id && (
                            <li onClick={() => setMenuOpen(false)}>
                                <a
                                    className={styles.navLink}
                                    href={`https://discord.com/oauth2/authorize?client_id=${app_id}&permissions=67584&scope=bot`}
                                    target="popup"
                                    onClick={onAddToServer}
                                >
                                    <FontAwesomeIcon icon={faSquarePlus}/>
                                    Add to Server
                                </a>
                            </li>
                        )}
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/server/settings`}
                            >
                                <FontAwesomeIcon icon={faServer}/>
                                Server Settings
                            </ActiveLink>
                        </li>
                    </ul>

                    <label>User</label>
                    <ul>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/user/memory`}
                            >
                                <FontAwesomeIcon icon={faBrainCircuit}/>
                                Memory
                            </ActiveLink>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <ActiveLink
                                className={styles.navLink}
                                href={`/${shape.username}/user/profile`}
                            >
                                <FontAwesomeIcon icon={faUser}/>
                                Preferences
                            </ActiveLink>
                        </li>
                    </ul>
                </>
            )}

            {(shape && shape.discord_bot_token_exist) && (
                <>
                    <ul>
                        <label>help</label>
                        <li onClick={() => setMenuOpen(false)}>
                            <a
                                className={styles.navLink}
                                href="https://wiki.shapes.inc"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faGraduationCap}/>
                                Discord User Manual
                            </a>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <a
                                className={styles.navLink}
                                href="/discord"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faDiscord}/>
                                Shapes, Inc.
                            </a>
                        </li>
                    </ul>
                </>
            )}

            {((!shape || (shape && !shape.x_id && !shape.discord_bot_token_exist)) && (
                <>
                    <label>Help</label>
                    <ul>
                        <li onClick={() => setMenuOpen(false)}>
                            <a
                                className={styles.navLink}
                                href="https://x.com/messages/compose?recipient_id=1288708818361360384&text=hi%20i%20have%20a%20question"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faXTwitter}/>
                                @shapes_inc
                            </a>
                        </li>
                        <li onClick={() => setMenuOpen(false)}>
                            <a
                                className={styles.navLink}
                                href="/discord"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faDiscord}/>
                                Discord Community
                            </a>
                        </li>
                    </ul>
                </>
            ))}

            {/*<ul>*/}
            {/*    copyright 2024 shapes, inc.*/}
            {/*</ul>*/}
            <ul>
                Shapes, Inc. ©️ 2024<br/><br/>
                made with ❤️ in sf
            </ul>

        </nav>
    );
}

export default SideNav;
