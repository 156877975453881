import styles from './level.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { clsx } from 'clsx';

function Level({ level, active = true }) {
    return (
        <span
            className={clsx([
                styles.level,
                !active ? styles.levelInactive : null,
            ])}
        >
            <FontAwesomeIcon icon={faTrophy} /> Level {level}
        </span>
    );
}

export default Level;
