import styles from './logo.module.scss';
import Link from 'next/link';

function Logo(props) {
    return (
        <Link className={styles.wrapper} href="/">
            <div className={styles.logoContainer}>
                <img src="https://crd573fr6fjpt2tz.public.blob.vercel-storage.com/shapesinc/src/assets/images/logo_colored-8Ubm0TWeUp4zjIqC7bv0ki8PVxflUa.png" alt="Logo" className={styles.logoImage} />
                <div className={styles.logoText}>shapes inc</div>
            </div>
        </Link>
    );
}

export default Logo;
