import styles from './topNav.module.scss';
import Logo from '@/components/logo/logo';
import NavAccount from '@/components/nav/navAccount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBars } from '@fortawesome/pro-solid-svg-icons';
import Button from '../button/button';
import Link from 'next/link';

function TopNav({ setMenuOpen }) {
    return (
        <div className={styles.wrapper}>
            <nav className={styles.nav}>
                <div className={styles.logoWrapper}>
                    <Logo />
                </div>
                <button
                    className={styles.menuButton}
                    onClick={() => setMenuOpen((prevState) => !prevState)}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <NavAccount />
            </nav>
        </div>
    );
}

export default TopNav;
