import styles from './button.module.scss';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

function Button({
    className = null,
    children = null,
    icon = null,
    isLoading = false,
    ...props
}) {
    return (
        <button
            className={clsx(
                styles.button,
                className,
                icon ? styles.buttonIcon : null
            )}
            {...props}
        >
            {isLoading && <FontAwesomeIcon icon={faSpinnerThird} spin={true} />}
            {icon && <FontAwesomeIcon icon={icon} />}
            {children}
        </button>
    );
}

export default Button;
