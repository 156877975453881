import styles from './selectedShape.module.scss';
import {useContext, useEffect, useMemo} from 'react';
import { ShapeContext } from '@/app/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGrid,
    faMousePointer,
    faRobot,
} from '@fortawesome/pro-solid-svg-icons';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { mutate } from 'swr';
import { Crisp } from 'crisp-sdk-web';
import Link from 'next/link';

function SelectedShape({ setMenuOpen }) {
    const { user } = useUser();
    const router = useRouter();
    const params = useParams();
    const pathname = usePathname();
    const { shape, shapes, setShapes, selectedShape, setSelectedShape } =
        useContext(ShapeContext);

    /*
    useEffect(() => {
        fetch('/api/shapes').then(async (r) => {
            const shapes = await r.json();

            const shapeMap = Object.fromEntries(
                shapes.map((e) => [e.username, e])
            );
            setShapes((prevShapes) => ({ ...prevShapes, ...shapeMap }));

            // For each shape set the SWR cache, so we can individually prune it
            shapes.forEach((s) => {
                mutate(`/shapes/${s.id}`, shape, { populateCache: false });
            });

            /*
            if (shapes.length > 0 && !shape) {
                if (!selectedShape && params.username) {
                    const shape = shapeMap[params.username];

                    if (shape) {
                        setSelectedShape(shape.username);
                    } else {
                        setSelectedShape(shapes[0].username);
                    }
                } else {
                    setSelectedShape(shapes[0].username);
                }
            }
            
    
        });
    }, []);
             */

    useEffect(() => {
        if (user && shape) {
            Crisp.session.setData({
                user_id: user.sub,
                shape_id: shape.id,
                shape_name: shape.name,
                shape_username: shape.username,
            });
        }
    }, [shape, user]);

    // const botId = shape?.app_info?.bot_id;
    // const botAvatar = shape?.app_info?.bot_avatar;
    // const profileUrl = `https://cdn.discordapp.com/avatars/${botId}/${botAvatar}.png?size=48`;
    const profileUrl = useMemo(() => {

        if (!shape) {
            return null
        }

        // Check for X-Account profile image first
        if (shape["X-Account"] &&
            typeof shape["X-Account"] === 'object' &&
            shape["X-Account"].profile_image_url) {
            let imageUrl = shape["X-Account"].profile_image_url;
            // Remove "_normal" from the end of the URL if present
            imageUrl = imageUrl.replace(/_normal(\.[^.]+)$/, '$1');
            return imageUrl;
        }

        // Fall back to Discord avatar if available
        if (shape.app_info?.bot_id && shape.app_info?.bot_avatar) {
            return `https://cdn.discordapp.com/avatars/${shape.app_info.bot_id}/${shape.app_info.bot_avatar}.png?size=256`;
        }

        // Return null if neither is available
        return null;
    }, [shape]);

    return (
        <>
            <label>Explore</label>
            <Link
                className={styles.wrapper}
                href="/explore"
                onClick={() => {
                    setMenuOpen(false);
                    setSelectedShape(null);
                }}
            >
                {shape && profileUrl && (
                    <img
                        className={styles.shapeAvatar}
                        width={24}
                        height={24}
                        alt={`${shape.name} avatar`}
                        src={profileUrl}
                    />
                )}
                {shape && !profileUrl && (
                    <div className={styles.shapeAvatar}>
                        <FontAwesomeIcon icon={faRobot} />
                    </div>
                )}

                {!shape && (
                    <div className={styles.shapeAvatar}>
                        <FontAwesomeIcon icon={faMousePointer} />
                    </div>
                )}
                <span>{shape ? shape.name : 'Explore'}</span>
                <div className={styles.exploreButton}>
                    <FontAwesomeIcon icon={faGrid} />{' '}
                </div>
            </Link>
        </>
    );
}

export default SelectedShape;
