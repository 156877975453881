'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import styles from './navAccount.module.scss';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faPlus,
    faPlusCircle,
    faPlusLarge,
} from '@fortawesome/pro-solid-svg-icons';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import Link from 'next/link';
import { clsx } from 'clsx';
import Level from '@/components/level/level';
import useSWR from 'swr';
import { useContext } from 'react';
import {ShapeContext, ShapeUserContext} from '@/app/providers';
import { useParams, usePathname, useRouter } from 'next/navigation';
import Button from '../button/button';
import AvatarImg from '../avatar/avatarImg';

function NavAccount(props) {
    const { user } = useUser();
    const { shapeUser, setShapeUser } = useContext(ShapeUserContext);
    const { shape, shapes, setShapes, selectedShape, setSelectedShape } =
        useContext(ShapeContext);

    useSWR(
        user ? '/api/users' : null,
        (url) =>
            fetch(url).then(async (r) => {
                const json = await r.json();

                setShapeUser(json);

                return shapeUser;
            }),
        { revalidateOnFocus: false }
    );

    return (
        user && (
            <div className={styles.wrapper}>
                {/* {shapeUser && (
                    <>
                        <Link
                            href={'/rewards'}
                            className={clsx([styles.navAccount, styles.levels])}
                        >
                            <Level level={shapeUser.level} />
                        </Link>
                    </>
                )} */}
                <Link
                    href="/create"
                    onClick={() => {
                        //setMenuOpen(false);
                        setSelectedShape(null);
                    }}>
                    <Button className={styles.createButton}>
                        <FontAwesomeIcon icon={faPlus} />
                        Create shape
                    </Button>
                </Link>
                <Menu
                    menuButton={
                        <div className={styles.navAccount}>
                            <AvatarImg
                                className={styles.navAvatar}
                                user={user}
                            />
                            <span>{user.name}</span>
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className={styles.caret}
                            />
                        </div>
                    }
                    menuClassName={styles.accountMenu}
                    align="end"
                    transition
                >
                    {/* <MenuItem href="/profile">Profile</MenuItem> */}

                    {/*<MenuItem href="/rewards">Rewards</MenuItem>*/}

                    {/*<MenuItem href="/gifts">Gifts</MenuItem>*/}

                    <MenuItem href="/discord" target="_blank">
                        Support server
                    </MenuItem>
                    <MenuItem href="/api/auth/logout">Logout</MenuItem>
                </Menu>
            </div>
        )
    );
}

export default NavAccount;
