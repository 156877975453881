export const admins = {
    anushk: 'oauth2|discord-oauth|556686510687256576',
    noorie: 'oauth2|discord-oauth|769699292264005632',
    para: 'oauth2|discord-oauth|308045225295872001',
    dhruv: 'oauth2|discord-oauth|1064365650551779419',
    promote: 'oauth2|discord-oauth|310285832680636418',
    yumi: 'oauth2|discord-oauth|985933812569628734',
    anushk_twitter: 'twitter|1260017196',
    noorie_twitter: 'twitter|1088508074753703937',
    para_twitter: 'twitter|1649807540870344705',
    dhruv_twitter: 'twitter|1619642656287780865',
    yumi_twitter: 'twitter|1668834078672719872',
};

export const adminSubs = Object.values(admins);

export function userSubToDiscordId(userSub) {
    return userSub.split('|')[2];
}

export function isUserShapeCreator(user, shapeUser, shape) {
    // If we're an admin, consider us good already
    if (adminSubs.includes(user.sub)) return true;

    const discordId = userSubToDiscordId(user.sub);

    if (shape.app_info?.owner?.individual) {
        if (shape.app_info.owner.individual.owner_id === discordId) return true;
    }

    if (shape.app_info?.owner?.team) {
        const creator = shape.app_info.owner.team.some(
            (o) => o.owner_id === discordId
        );
        if (creator) {
            return true;
        }
    }

    return shapeUser && shape.user_id?.includes(shapeUser.id);
}
