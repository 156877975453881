'use client';

function AvatarImg({ user, className = null, src = null}) {
    return (
        <img
            className={className}
            src={src || user.picture}
            alt="User profile picture"
            onError={(e) => {
                e.currentTarget.onerror = null;
                const discord_id = user?.sub?.length ? user.sub.split('|')[2] : user;
                if (discord_id) {
                    const avatar_id = (discord_id >> 22) % 5;
                    e.currentTarget.src = `https://cdn.discordapp.com/embed/avatars/${avatar_id}.png`;
                } else {
                    e.currentTarget.src = `https://cdn.discordapp.com/embed/avatars/0.png`;
                }
            }}
        />
    );
}

export default AvatarImg;
