'use client';

import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';

const ActiveLink = ({
    children,
    activeClassName = 'active',
    className = '',
    href = null,
    ...props
}) => {
    const pathname = usePathname();
    const [computedClassName, setComputedClassName] = useState(className);

    useEffect(() => {
        const newClassName =
            href === pathname
                ? `${className} ${activeClassName}`.trim()
                : className;

        if (newClassName !== computedClassName) {
            setComputedClassName(newClassName);
        }
    }, [pathname]);

    if (!href) {
        return (
            <span className={computedClassName} {...props}>
                {children}
            </span>
        );
    }

    return (
        <Link className={computedClassName} href={href} {...props}>
            {children}
        </Link>
    );
};

export default ActiveLink;
